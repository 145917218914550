const postal_code_regex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
const phone_number_regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
const email_regex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;
const ab_health_regex = /^[0-9]{5}-[0-9]{4}$/;

const validators = {
    isNotEmpty: function (val) {
        if (typeof val === 'string')
            return val.trim().length !== 0;
        else if (Array.isArray(val))
            return val.length !== 0;
        else if (typeof val === 'object' && val !== null)
            return Object.keys(val).length !== 0;

        return true;
    },
    isPostalCode: function (postal_code) {
        return postal_code_regex.test(postal_code);
    },
    isPhoneNumber: function(phone_number) {
        return phone_number_regex.test(phone_number);
    },
    isEmail: function(email) {
        return email_regex.test(email);
    },
    isTrue: function(bool) {
        return bool === true;
    },
    isAlbertaHealthCareNumber : function(care_number)
    {
        return ab_health_regex.test(care_number);
    },
    isSigned : function(canvas)
    {
        const ctx = canvas.getContext("2d");
        const signed = ctx.getImageData(0, 0, canvas.width, canvas.height).data.some(channel => channel !== 0);
        return signed;
    }
}

// TODO consider outputting the regex used
// export default validators;
module.exports = validators;