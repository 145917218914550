<template>
<div class="camper-info">
    <template v-for="i in Number(signup.info.guardians.length)" :key="'guardian_' + i">
        <h3 class="camper-info__header camper-info__header-info">Guardian {{ i }}</h3>
        <div class="camper-info__container">
            <div class="camper-info__group camper-info__group--2">
                <sign-up-input
                    :id="`guardian_${i}_firstname`"
                    type="text"
                    label="First name"
                    :required="i == 1 ? true : false"
                    :state_var="signup.info.guardians[i-1].firstname"
                    mutate_path="signup/MUTATE_GUARDIAN"
                    :mutate_args="{ key: 'firstname', idx: i-1 }"
                    validator="isNotEmpty"
                    validator_text="This field is required"
                />
                <sign-up-input
                    :id="`guardian_${i}_lastname`"
                    type="text"
                    label="Last name"
                    :required="i == 1 ? true : false"
                    :state_var="signup.info.guardians[i-1].lastname"
                    mutate_path="signup/MUTATE_GUARDIAN"
                    :mutate_args="{ key: 'lastname', idx: i-1 }"
                    validator="isNotEmpty"
                    validator_text="This field is required"
                />
                <sign-up-input
                    :id="`guardian_${i}_primary_number`"
                    type="tel"
                    label="Primary contact #"
                    :click_function="TelClick"
                    :before_input_function="TelBeforeInputEvent"
                    :input_function="TelInputEvent"
                    :focus_function="TelFocusEvent"
                    :blur_function="TelBlurEvent"
                    max_length="14"
                    :required="i == 1 ? true : false"
                    :state_var="signup.info.guardians[i-1].primary_number"
                    mutate_path="signup/MUTATE_GUARDIAN"
                    :mutate_args="{ key: 'primary_number', idx: i-1 }"
                    validator="isPhoneNumber"
                    validator_text="Invalid phone number ex. 123-777-5555"
                    validator_timeout="500"
                />
                <sign-up-input
                    :id="`guardian_${i}_secondary_number`"
                    type="tel"
                    label="Secondary contact #"
                    placeholder="Secondary contact # (optional)"
                    :click_function="TelClick"
                    :before_input_function="TelBeforeInputEvent"
                    :input_function="TelInputEvent"
                    :focus_function="TelFocusEvent"
                    :blur_function="TelBlurEvent"
                    max_length="14"
                    :required="false"
                    :state_var="signup.info.guardians[i-1].secondary_number"
                    mutate_path="signup/MUTATE_GUARDIAN"
                    :mutate_args="{ key: 'secondary_number', idx: i-1 }"
                    validator="isPhoneNumber"
                    validator_text="Invalid phone number ex. 123-777-5555"
                    validator_timeout="500"
                />
                <sign-up-input
                    :id="`guardian_${i}_relation`"
                    type="text"
                    label="Relation"
                    :required="i == 1 ? true : false"
                    :state_var="signup.info.guardians[i-1].relation"
                    mutate_path="signup/MUTATE_GUARDIAN"
                    :mutate_args="{ key: 'relation', idx: i-1 }"
                    validator="isNotEmpty"
                    validator_text="This field is required"
                />
                <sign-up-input
                    :id="`guardian_${i}_email_address`"
                    type="text"
                    label="Email address"
                    :required="i == 1 ? true : false"
                    :state_var="signup.info.guardians[i-1].email_address"
                    mutate_path="signup/MUTATE_GUARDIAN"
                    :mutate_args="{ key: 'email_address', idx: i-1 }"
                    validator="isEmail"
                    validator_text="Invalid email ex. info@riversidebiblecamp.com"
                    validator_timeout="500"
                />
            </div>
        </div>
    </template>
</div>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from 'vuex'

import SignUpInput from "./input/SignUpInput.vue";

import transitionLabel from '../../mixins/transitionLabel'
import commitSimpleInput from '../../mixins/commitSimpleInput'

export default defineComponent({
    name: "CamperSignUpFormGuardianInfo",
    components: {
        SignUpInput
    },
    data() {
        return {
            prev_val: "",
            cursor_pos: 1,
        }
    },
    mixins: [
        commitSimpleInput,
        transitionLabel
    ],
    computed: {
        ...mapState(["signup"]),
    },
    methods: {
        TelClick(event)
        {
            event.target.setSelectionRange(this.cursor_pos, this.cursor_pos);
        },
        TelBeforeInputEvent(event)
        {
            if (event.data != null && isNaN(event.data))
            {
                event.preventDefault();
                return;
            }

            if (event.inputType == "deleteContentForward")
            {
                event.preventDefault();
            }
            else if (event.inputType == "deleteContentBackward")
            {
                let value = event.target.value;
                const back_char = event.target.value.substr(this.cursor_pos-1, 1);
                if (back_char == "(" || back_char == ")")
                {
                    event.preventDefault();
                }
                else if (back_char == "-")
                {
                    // Move it back two and delete a number
                    this.cursor_pos -= 2;
                    value = value.substr(0, this.cursor_pos) + " " +
                        value.substr(this.cursor_pos+1);
                    event.target.value = value;
                    event.preventDefault();
                }
                else if (back_char == " ")
                {
                    this.cursor_pos -= 3;
                    value = value.substr(0, this.cursor_pos) + " " +
                        value.substr(this.cursor_pos+1);
                    event.target.value = value;
                    event.preventDefault();
                }
                else
                {
                    this.cursor_pos--;
                    if (this.cursor_pos >= 1 && this.cursor_pos <= 8)
                    {
                        // Inside the brackets
                        value = value.substr(0, this.cursor_pos)
                            + " " + event.target.value.substr(this.cursor_pos+1);
                    }
                    else
                    {
                        value = value.substr(0, this.cursor_pos);
                    }
                    event.target.value = value;
                    event.preventDefault();
                }
            }
            else
            {
                event.target.setSelectionRange(this.cursor_pos, this.cursor_pos);
            }

            // Sanity check
            if (this.cursor_pos < 0)
            {
                this.cursor_pos = 1;
            }

            event.target.setSelectionRange(this.cursor_pos, this.cursor_pos);
        },
        TelInputEvent(event)
        {
            // TODO move into the before input and just prevent default?
            let value = event.target.value;
            this.cursor_pos++;
            if (value.length > this.prev_val.length)
            {
                let next_char = value.substr(this.cursor_pos+1, 1);
                if (next_char == " ")
                {
                    value = value.substr(0, this.cursor_pos) +
                        value.substr(this.cursor_pos+1);
                }
                else if (next_char == ")")
                {
                    value = value.substr(0, this.cursor_pos) +
                        value.substr(this.cursor_pos+1);
                    this.cursor_pos += 2;
                }
                else if (next_char == "-")
                {
                    value = value.substr(0, this.cursor_pos) +
                        value.substr(this.cursor_pos+1);
                    this.cursor_pos += 1;
                }
            }

            event.target.value = value;
            event.target.setSelectionRange(this.cursor_pos, this.cursor_pos);
            this.prev_val = value;
        },
        TelFocusEvent(event)
        {
            if (event.target.value != "") return;
            event.target.value = "(   )    -";
            this.prev_val = event.target.value;
            this.cursor_pos = 1;
            event.target.setSelectionRange(this.cursor_pos, this.cursor_pos);
        },
        TelBlurEvent(event)
        {
            if (event.target.value != "(   )    -") return;
            event.target.value = "";
        }
    }
});
</script>