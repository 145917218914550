<template>
<div class="camper-info">
    <h3 class="camper-info__header camper-info__header-info">Personal Information</h3>
    <div class="camper-info__group camper-info__group--1">
        <sign-up-dropdown
            :id="`camper_${camper_idx}_camp_week`"
            label="Camp week"
            :state_var="signup.info.campers[camper_idx].meta.selected_camp_week_idx"
            mutate_path="signup/MUTATE_CAMPER_META"
            :mutate_args="{ key: 'selected_camp_week_idx', idx: this.camper_idx }"
            validator="isNotEmpty"
            validator_text="This field is required"
            default_item="Camp Week"
            :options="signup.camp_weeks"
        />
    </div>

    <!-- Begin first and last name group -->
    <div class="camper-info__group camper-info__group--2">
        <sign-up-input
            :id="`camper_${camper_idx}_firstname`"
            type="text"
            label="First name"
            :state_var="signup.info.campers[camper_idx].firstname"
            mutate_path="signup/MUTATE_CAMPER"
            :mutate_args="{ key: 'firstname', idx: this.camper_idx }"
            validator="isNotEmpty"
            validator_text="This field is required"
        />
        <sign-up-input
            :id="`camper_${camper_idx}_lastname`"
            type="text"
            label="Last name"
            :state_var="signup.info.campers[camper_idx].lastname"
            mutate_path="signup/MUTATE_CAMPER"
            :mutate_args="{ key: 'lastname', idx: this.camper_idx }"
            validator="isNotEmpty"
            validator_text="This field is required"
        />
    </div>
    <!-- end of first and last name group -->

    <!-- start gender, and Date of birth -->
    <div class="camper-info__group camper-info__group--2">
        <sign-up-input
            :id="`camper_${camper_idx}_date_of_birth`"
            type="date"
            label="Date of Birth"
            max_date="9999-12-31"
            :state_var="signup.info.campers[camper_idx].date_of_birth"
            mutate_path="signup/MUTATE_CAMPER"
            :mutate_args="{ key: 'date_of_birth', idx: this.camper_idx }"
            validator="isNotEmpty"
            :validator_text="`Please enter the camper's date of birth`"
        />
        <sign-up-dropdown
            :id="`camper_${camper_idx}_gender`"
            label="Gender"
            :state_var="signup.info.campers[camper_idx].gender"
            mutate_path="signup/MUTATE_CAMPER"
            :mutate_args="{ key: 'gender', idx: this.camper_idx }"
            validator="isNotEmpty"
            validator_text="This field is required"
        >
                <option value="" selected="true" disabled hidden>Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
        </sign-up-dropdown>
    </div>
    <!-- end gender, and Date of birth -->

    <!-- Begin church and cabin partner -->
    <div class="camper-info__group camper-info__group--2">
        <sign-up-input
            :id="`camper_${camper_idx}_church`"
            type="text"
            label="Church (optional)"
            :state_var="signup.info.campers[camper_idx].church"
            mutate_path="signup/MUTATE_CAMPER"
            :mutate_args="{ key: 'church', idx: this.camper_idx }"
        />
        <sign-up-input
            :id="`camper_${camper_idx}_cabin_partner`"
            type="text"
            label="Cabin partner [only one] (optional)"
            :state_var="signup.info.campers[camper_idx].cabin_partner"
            mutate_path="signup/MUTATE_CAMPER"
            :mutate_args="{ key: 'cabin_partner', idx: this.camper_idx }"
        />
    </div>
    <!-- End church and cabin partner -->
</div>
</template>

<script>
import { defineComponent } from "vue";

import { mapGetters, mapState } from "vuex";
import transitionLabel from "../../mixins/transitionLabel.js";
import SignUpDropdown from "./input/SignUpDropdown.vue";
import SignUpInput from "./input/SignUpInput";

export default defineComponent ({
    name: "CamperSignUpFormBasicInfo",
    props: [
        'camper_idx'
    ],
    components: {
        SignUpDropdown,
        SignUpInput
    },
    mixins: [
        transitionLabel
    ],
    computed: {
        ...mapState(["signup"]),
        ...mapState("admin", ["campers"]),
        ...mapState(["validator"]),
        ...mapGetters("validator", ["getIsValid"])
    },
    methods: {
        updateDropdownColor(event)
        {
            event.target.classList.remove("light");
            event.target.classList.add("primary");
        },
        dateValidator(value)
        {
            if (value == "")
            {
                return false;
            }

            let dob = new Date(value);

            if (dob < new Date(this.getMaxDate()) && dob > new Date(this.getMinDate()))
            {
                return true;
            }

            return false;
        },
        getMinDate()
        {
            let date = new Date();
            let min_day = new Date(`${date.getFullYear()}-12-31T00:00:00.000Z`);
            min_day.setFullYear(date.getFullYear() - 20);

            return `${min_day.getFullYear()}-${min_day.getMonth()}-${min_day.getDate()}`;
        },
        getMaxDate()
        {
            let date = new Date();
            let max_day = new Date(`${date.getFullYear()}-12-31T00:00:00.000Z`);
            max_day.setFullYear(date.getFullYear() - 5);
            return `${max_day.getFullYear()}-${max_day.getMonth()}-${max_day.getDate()}`;
        }

    }
});
</script>