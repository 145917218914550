import axios from "axios"

export default {
    // TODO error handling
    serverGet({ state }, kwargs) {
        // console.log("GET url ->", state.base_url + kwargs.route)
        return new Promise((resolve, reject) => {
            axios.get(state.base_url + kwargs.route, {
                params: kwargs.params,
                withCredentials: true            }).then((response) => {
                // console.log("GET response ->", response);
                resolve(response);
            }).catch((err) => {
                // console.error("GET error ->", err.response);
                reject(err);
            });
        });
    },
    serverPost({ state }, kwargs) {
        // console.log("POST url ->", state.base_url + kwargs.route)
        return new Promise((resolve, reject) => {
            axios.post(state.base_url + kwargs.route, kwargs.data, {
                withCredentials: true
            }).then((response) => {
                console.log("POST response ->", response);
                resolve(response);
            }).catch((err) => {
                console.error("POST error ->", err.response);
                reject(err);
            });
        });
    }
}