<template>
    <div v-if="loading['enabled']" class="loading-overlay" @click.prevent>
        {{ loading.text }}
    </div>

</template>

<script lang="js">
import { defineComponent } from 'vue';

import { mapState } from 'vuex';

export default defineComponent({
    name: "LoadingOverlay",
    computed: {
        ...mapState("events", ["loading"]),
    }
});

</script>