function CampWeeks()
{
    return [
        {
            date: "2024/06/07",
            value: "Sr. High (16-18)",
            text: "July 7-12. Sr. High (16-18 yrs.) - $175",
            price: 175
        },
        {
            date: "2024/06/14",
            value: "Children (8-10)",
            text: "July 14-19. Children (8-10 yrs.) - $110",
            price: 110
        },
        {
            date: "2024/06/21",
            value: "Jr. High (13-15)",
            text: "July 21-26. Jr. High (13-15 yrs.) - $135",
            price: 135,
        },
        {
            date: "2024/06/28",
            value: "Kids (10-11)",
            text: "July 28-Aug 2. Kids (10-11 yrs.) - $115",
            price: 115
        },
        {
            date: "2024/07/06",
            value: "Squirts (6-8)",
            text: "Aug 6-8. Squirts (6-8 yrs.) - $55",
            price: 55
        },
        {
            date: "2024/07/11",
            value: "Intermediates (11-12)",
            text: "Aug 11-16. Intermediates (11-12 yrs.) - $115",
            price: 115
        },
        {
            date: "2024/07/16",
            value: "Adults Weekend (18)",
            text: "Aug 16-18. Adults Weekend (18+ yrs.) - $65",
            price: 65
        }
    ];
}


function defaultGuardianAndEmergencyContactInfo()
{
    return {
        firstname: "",
        lastname: "",
        primary_number: "",
        secondary_number: "",
        email_address: "",
        relation: ""
    }
};

function testGuardianAndEmergencyContactInfo(idx)
{
    return {
        firstname: `gf${idx}`,
        lastname:  `gl${idx}`,
        primary_number: `(123) 123-1231`,
        secondary_number: `(222) 222-2222`,
        email_address: `ge${idx}@gmail.com`,
        relation: `gr${idx}`
    }
};

function defaultPickupPerson()
{
    return {
        firstname: "",
        lastname: "",
        primary_number: "",
        secondary_number: ""
    }
};

function testPickupPerson()
{
    return {
        firstname: "ppf",
        lastname: "ppl",
        primary_number: `(333) 333-3333`,
        secondary_number: `(444) 444-4444`
    }
};

function defaultCamper()
{
    return {
        // TODO review the variables in the signup page to make sure they all
        // are here.
        camp_week: "",
        firstname: "",
        lastname: "",
        date_of_birth: null,
        age: null,
        gender: null,
        church: "",
        cabin_partner: "",
        health_care_card: "",
        allergies: "",
        medical_conditions: [],
        other_health_conditions: "",
        physical_restrictions: "",
        medical_bans: "",
        care_requirements: "",
        rock_wall_release: false,
        meta: {
            selected_camp_week_idx: null
        }
    }
};

function testCamper(idx)
{
    return {
        camp_week: "",
        firstname: "camper f",
        lastname: "camper l",
        date_of_birth: "2014-12-10",
        age: null,
        gender: "male",
        church: "nampa",
        cabin_partner: "jenny",
        health_care_card: "12312-3123",
        allergies: "allergies",
        medical_conditions: ["adhd", "autism", "bed_wetting"],
        other_health_conditions: "my other health conditions",
        physical_restrictions: "my other physical restrictions",
        medical_bans: "medicine bans",
        care_requirements: "my care requirements",
        rock_wall_release: false,
        meta: {
            selected_camp_week_idx: 3
        }
    }
};

function CreateDefaultState()
{
    return {
        camp_weeks: CampWeeks(),
        info: {
            campers: [defaultCamper()],
            guardians: [defaultGuardianAndEmergencyContactInfo(), defaultGuardianAndEmergencyContactInfo()],
            emergency_contacts: defaultGuardianAndEmergencyContactInfo(),
            pickup_person: defaultPickupPerson(),
            mailing_address: "",
            city: "",
            province: "",
            postal_code: "",
            important_info_accepted: false,
            photo_terms_accepted: false,
            liability_released: false,
            physician_released: false,
            terms_accepted: false,
            signed: false
        }
    }
}

function CreateTestState()
{
    return {
        camp_weeks: CampWeeks(),
        info: {
            campers: [testCamper()],
            guardians: [testGuardianAndEmergencyContactInfo(1), testGuardianAndEmergencyContactInfo(2)],
            emergency_contacts: testGuardianAndEmergencyContactInfo(1),
            pickup_person: testPickupPerson(),
            mailing_address: "9708 102 street",
            city: "nampa",
            province: "AB",
            postal_code: "t0h 2r0",
            important_info_accepted: false,
            photo_terms_accepted: false,
            liability_released: false,
            physician_released: false,
            terms_accepted: false,
            signed: false
        }
    }
}

export default {
    namespaced: true,
    name: "SignUp",
    state: CreateDefaultState(),
    actions: {
        updateField({ state, commit }, kwargs)
        {
            commit('MUTATE', kwargs);
        }
    },
    getters: {
        getCamperFullName: (state) => (camper_idx) =>
        {
            let fullname = "";
            if (state.info.campers[camper_idx].firstname != "")
            {
                fullname = state.info.campers[camper_idx].firstname;
            }

            if (state.info.campers[camper_idx].lastname != "")
            {
                if (fullname != "")
                {
                    fullname += " ";
                }
                fullname += state.info.campers[camper_idx].lastname;
            }

            return fullname;
        }
    },
    mutations: {
        MUTATE(state, { key, val })
        {
            state[key] = val;
        },
        MUTATE_INFO(state, { key, val })
        {
            state.info[key] = val;
        },
        MUTATE_CAMPER(state, { idx, key, val })
        {
            state.info.campers[idx][key] = val;
        },
        MUTATE_CAMPER_META(state, { idx, key, val })
        {
            state.info.campers[idx].meta[key] = val;
        },
        APPEND_NEW_CAMPER(state)
        {
            state.info.campers.push(defaultCamper());
        },
        REMOVE_LAST_CAMPER(state)
        {
            if (state.info.campers.length > 1)
            {
                state.info.campers.pop();
            }
        },
        APPEND_EMERGENCY_CONTACT(state, camper_idx)
        {
            // state.info.campers[camper_idx].emergency_contacts.push(defaultGuardianAndEmergencyContactInfo());
            state.info.emergency_contacts.push(defaultGuardianAndEmergencyContactInfo());
        },
        MUTATE_EMERGENCY_CONTACT(state, { key, val })
        {
            // state.info.campers[camper_idx].emergency_contacts[emergency_contact_idx][key] = val;
            state.info.emergency_contacts[key] = val;
        },
        APPEND_GUARDIAN(state, guardian)
        {
            state.info.guardians.append(guardian);
        },
        REMOVE_GUARDIAN(state)
        {
            if (state.info.guardians.length > 1)
            {
                state.info.guardians.pop();
            }
        },
        MUTATE_GUARDIAN(state, { idx, key, val })
        {
            state.info.guardians[idx][key] = val;
        },
        MUTATE_PICKUP_PERSON(state, { key, val })
        {
            state.info.pickup_person[key] = val;
        },
    },
    modules: {
    }
}