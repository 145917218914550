
// TODO this broke
import validators from "shared_lib/validators.js";

function defaultField() {
    return {
        validator: "",
        message: "",
        required: true,
        is_valid: false,
        val: "",
    };
}

function defaultState() {
    return {
        validators: validators,
        fields: {
        }
    }
}

export default {
    namespaced: true,
    name: "Validator",
    state: defaultState(),
    actions: {
        register({commit}, {key, validator, message="Required", required=true})
        {
            // TODO check if validator exists otherwise reject
            // TODO check that message is at least len 1

            commit("COMMIT_REGISTER", {
                key: key,
                validator: validator,
                message: message,
                required: required
            });
        },
        register_object({commit}, {key, validator, obj, message="Required", required=true})
        {
            commit("COMMIT_REGISTER_OBJ", {
                key: key,
                validator: validator,
                obj: obj,
                message: message,
                required: required
            });
        },
        validate({state, commit}, {key, val}) {
            return new Promise((resolve) => {
                // Get the validator
                const validator_select = state.fields[key].validator;

                let validator = null;
                if (typeof validator_select == "string")
                {
                    validator = state.validators[validator_select];
                }
                else if (typeof validator_select == "function")
                {
                    validator = validator_select;
                }

                // Run the validator
                let is_valid = validator(val);

                // Check if the field is required
                // TODO need to check if string?
                if (!state.fields[key].required && val.length === 0)
                is_valid = null;

                commit("COMMIT_VALIDATION", {key, val, is_valid});
                resolve();
            });
        },
        invalidate({state, dispatch}, key) {
            return new Promise(async (resolve) => {
                dispatch("validate", {
                    key: key,
                    val: state.fields[key].val
                });
            });
        },
        validateAll({state, dispatch}) {
            return new Promise(async (resolve) => {
                // Run the validator for each
                let promises = [];
                Object.keys(state.fields).forEach((key) => {
                    promises.push(dispatch("validate", {
                        key: key,
                        val: state.fields[key].val
                    }));
                });

                await Promise.all(promises);
                resolve();
            });
        }
    },
    mutations: {
        COMMIT_REGISTER(state, {key, validator, message, required}) {
            state.fields[key] = {
                validator,
                message,
                required,
                is_valid: null,
                val: ""
            };
        },
        COMMIT_REGISTER_OBJ(state, {key, validator, obj, message, required}) {
            state.fields[key] = {
                validator,
                message,
                required,
                is_valid: null,
                val: obj
            };
        },
        COMMIT_VALIDATION(state, {key, val, is_valid}) {
            state.fields[key]['val'] = val;
            state.fields[key]['is_valid'] = is_valid;
        },
    },
    getters: {
        // Accept_null is a boolean that determines if
        // null is acceptable or not
        getIsValid: (state) => (key) => {
            if (state.fields[key] == undefined) return true;

            if (state.fields[key]['is_valid'] !== null) {
                return state.fields[key]['is_valid'];
            }
            return true;
        },
        getFieldMessage: (state) => (key) => {
            if (state['fields'][key]) return state['fields'][key].message;

            return "&emsp"
        },
        getIsAllValid: (state) => () => {
            let all_valid = true;
            Object.keys(state['fields']).forEach(key => {
                if (state.fields[key].is_valid == null) return;

                all_valid = all_valid && state['fields'][key].is_valid;
            });

            return all_valid;
        },
        getInvalidFields: (state) => () => {
            let fields = [];
            Object.keys(state['fields']).forEach(key => {
                if (state.fields[key].is_valid == null) return;
                if (state['fields'][key].is_valid) return;

                fields.push(key);
            });

            return fields;
        }
    }
}