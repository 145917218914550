<template>
  <div class="camper-info">
    <p>
      <b>Please read over the terms and conditions carefully and check each box.</b>
    </p>
    <div class="release-group">
    <sign-up-checkbox
      id="signup_photo_terms_accepted"
      :state_var="signup.info.photo_terms_accepted"
      mutate_path="signup/MUTATE_INFO"
      :mutate_args="{ key: 'photo_terms_accepted' }"
      :required="false"
    >
      <b>I give permission for my child to be photographed and for their pictures to be used for promotional purposes. </b>
    </sign-up-checkbox>
    <sign-up-checkbox
      id="signup_physician_released"
      :state_var="signup.info.physician_released"
      mutate_path="signup/MUTATE_INFO"
      :mutate_args="{ key: 'physician_released' }"
      :required="false"
    >
      <b>In case of emergency, I hereby give permission to the physician selected by the camp to hospitalize, secure proper treatment for, and order proper procedures for the treatment.</b>
    </sign-up-checkbox>
    <sign-up-checkbox
      id="signup_liability_released"
      :state_var="signup.info.liability_released"
      mutate_path="signup/MUTATE_INFO"
      :mutate_args="{ key: 'liability_released' }"
      validator="isTrue"
      validator_text="This field is required."
    >
      <b>The undersigned, intending to be bound hereby, realizing it is the camp's desire to give each camper a safe and beneficial stay and realizing each camper is covered by reputable insurance plan, releases forever the Riverside Bible Camp and all individuals associated therewith, from and all liability for and injury or damage which may be sustained by the undersigned and/or child of the undersigned or property of the same at or in transit to or from any camp conducted activity or under the auspices of the Riverside Gospel Fellowship.</b>
    </sign-up-checkbox>
    <sign-up-checkbox
      id="signup_terms_accepted"
      :state_var="signup.info.terms_accepted"
      mutate_path="signup/MUTATE_INFO"
      :mutate_args="{ key: 'terms_accepted' }"
      validator="isTrue"
      validator_text="This field is required."
    >
      <b>
        I have read and accepted the above terms and conditions.
      </b>
    </sign-up-checkbox>

    <!-- <input
        v-model="signup.terms_accepted"
        value="true"
        type="checkbox"
        :id="'terms_accepted'"
        class="camper-info__input camper-info__input--checkbox"
        required="required"
    />
    <label :for="'terms_accepted'" class="camper-info__label--checkbox">
      <b>I have read and accept the above terms and conditions.</b>
    </label> -->
            <!-- </p> -->
      <!-- <span class="btn btn--global btn--open-release" @click="openRelease">
        <span>Open Release</span>
      </span> -->

      <!-- <span> Release Accepted:
        <i v-if="signup.terms_accepted" class="fas fa-check release-icon--accepted"></i>
        <i v-if="!signup.terms_accepted" class="fas fa-times release-icon--rejected"></i>

      </span> -->
    </div>
    <!-- <release-modal /> -->
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { mapState } from "vuex";
import SignUpCheckbox from "./input/SignUpCheckbox.vue";
// import ReleaseModal from "../ReleaseModal.vue";

export default defineComponent ({
  name: "CamperSignUpFormRelease",
  components: {
    // ReleaseModal,
    SignUpCheckbox
  },
  computed: {
    ...mapState(["signup"]),
  },
  methods: {
    openRelease() {
      this.$store
        .dispatch("events/raiseEvent", {
          key: "open_modals",
          obj: {
            id: "release_modal",
          },
        })
        .then(() => {
          // console.log(this.$store.state.events);
        });
    },
  },
});
</script>