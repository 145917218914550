<template>
    <layout>
        <template v-slot:content>
            <div class="riverside__title">
                <h2>
                    Riverside Bible Camp
                </h2>
            </div>
            <div class="thank-you__container">
                <div class="thank-you__wrapper">
                    <h3>
                        Thank you for signing up for
                        <span
                            v-for="(camper, idx) in this.campers"
                            :key="`signed_up_week_${idx}`"
                        >
                        <span v-if="idx == this.campers.length - 1 &&
                                    this.campers.length != 1">
                            and
                        </span>
                        <span v-if="this.campers.length > 1 &&
                                    idx != this.campers.length - 1"
                        >
                            {{ camper.camp_week }},
                        </span>
                        <span v-else>{{ camper.camp_week }}</span>
                        </span>
                    </h3>
                    <p>You'll be redirected back to our homepage soon.</p>
                    <div><b>{{ this.duration }}</b></div>
                </div>
            </div>
        </template>
    </layout>
</template>

<script>

import { defineComponent } from "vue";
import { mapState } from "vuex";
import Layout from "../../templates/Layout.vue";

export default defineComponent({
    name: "ThankYou",
    components: {
        Layout,
    },
    computed: {
        ...mapState("signup", ["campers"])
    },
    data() {
        return {
            duration: 5,
            timeout_id: -1,
            interval_id: -1
        }
    },
    mounted() {
        // Make a time
        this.interval_id = setInterval(() => {
            if (this.duration == 0)
            {
                clearInterval(this.interval_id);
                return;
            }
            this.duration--;
        }, 1000);
        this.timeout_id = setTimeout(() => {
            window.location.href="https://www.riversidebiblecamp.com/";
        }, 5000);
    },
    unmounted() {
        clearTimeout(this.timeout_id);
        clearInterval(this.interval_id);
    }
});

</script>