import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router.js';
import store from './store';

import './registerServiceWorker';

/* SCSS imports */
import "./scss/default.scss";

document.title = "Register Riverside Bible Camp";

/** Dev tools **/
if (process.env.NODE_ENV === 'development') {
    store.commit("MUTATE", {
        key: "base_url",
        val: "http://localhost:3080/api/"
    })
} else {
    store.commit("MUTATE", {
        key: "base_url",
        val: "/api/"
    });
}

const app = createApp(App);
app.use(store)
app.use(router)

app.mount('#app');
app.config.performance = true;
