
import jsPDF from "jspdf";
import html2canvas from "html2canvas"

export default async (css_class="pdf-view") => {
    const content = document.querySelector("#content");
    content.classList.toggle(css_class);

    const canvas = await html2canvas(content, {scale: 1, allowTaint: false});
    content.classList.toggle(css_class);
    const img_data = canvas.toDataURL("image/jpg");
    const page_width = 210;
    const page_height = 280;
    const img_height = canvas.height * page_width / canvas.width;
    let remaining_height = img_height;

    const pdf = new jsPDF('p', 'mm', "letter");
    let position = remaining_height-img_height

    pdf.addImage(img_data, "jpg", 0, position, page_width, img_height, null, "FAST");
    remaining_height -= (page_height);

    while (remaining_height >= 0)
    {
        position = remaining_height - img_height;
        pdf.addPage();
        pdf.addImage(img_data, "jpg", 0, position, page_width, img_height, null, "FAST");
        remaining_height -= page_height;
    }

    return pdf;
}