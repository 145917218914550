<template>
<div class="camper-info__content">
    <div class="camper-info__group camper-info__group-1">
        <canvas :id="`${id}_canvas`" class="signature_canvas" :width="width" :height="height" ref="canvas" z-index="100" @touchstart="TouchStartDrawing" @mousedown="MouseStartDrawing"></canvas>
    </div>
    <div class="camper-info__content__validator"
        :class="!isValid() ? 'show--opacity' : 'hide--opacity'"
    >
        {{ getFieldMessage(this.id) }}
    </div>
</div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters, mapState } from "vuex";

export default defineComponent({
    name: "CamperSignupFormSignature",
    computed: {
        ...mapState("signup", ["info"]),
        ...mapGetters("validator", ["getIsValid", "getFieldMessage"])
    },
    props: {
        id: { },
        required: { default: true },
        state_var: { },
        mutate_path: { },
        mutate_args: { },
        validator_text: { default: "" },
        width: { default: 350 },
        height: { default: 140 }
    },
    data()
    {
        return {
            is_drawing: false,
            x: 0,
            y: 0
        }
    },
    mounted()
    {
        // Register validator
        this.$store.dispatch("validator/register_object", {
            key: this.id,
            validator: "isSigned",
            obj: this.$refs.canvas,
            message: this.validator_text,
            required: this.required
        });

        window.addEventListener('mouseup', this.StopDrawing, false);
        window.addEventListener('mousemove', this.MouseDraw, false);
        window.addEventListener('touchend', this.StopDrawing, false);
        window.addEventListener('touchmove', this.TouchDraw, false);
    },
    methods: {
        MouseStartDrawing(event)
        {
            this.StartDrawing(event.clientX, event.clientY);
        },
        TouchStartDrawing(event)
        {
            event.preventDefault();
            this.StartDrawing(event.touches[0].clientX, event.touches[0].clientY);
        },
        MouseDraw(event)
        {
            this.Draw(event.clientX, event.clientY);
        },
        TouchDraw(event)
        {
            event.preventDefault();
            this.Draw(event.touches[0].clientX, event.touches[0].clientY);
        },
        StartDrawing(user_x, user_y)
        {
            const canvas = this.$refs.canvas;
            const rect = canvas.getBoundingClientRect();
            this.is_drawing = true;

            this.x = user_x - rect.left;
            this.y = user_y - rect.top;
        },
        StopDrawing()
        {
            this.is_drawing = false;
        },
        Draw(user_x, user_y)
        {
            if (!this.is_drawing) return;
            if (this.state_var) return;
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext("2d");
            const rect = canvas.getBoundingClientRect();

            ctx.lineWidth = 2;
            ctx.lineCap = "round";
            ctx.strokeStyle = "#000";

            let x = user_x - rect.left;
            let y = user_y - rect.top;
            if (user_x > rect.right)
            {
                x = rect.width;
            }
            else if (user_x < rect.left)
            {
                x = 0;
            }

            if (user_y > rect.bottom)
            {
                y = rect.height;
            }
            else if (user_y < rect.top)
            {
                y = 0;
            }


            ctx.beginPath();
            ctx.moveTo(this.x, this.y);
            ctx.lineTo(x, y);
            ctx.stroke();
            this.x = x;
            this.y = y;

        },
        ResetSignature()
        {
            const ctx = this.$refs.canvas.getContext("2d");
            ctx.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);

            this.$store.commit(this.mutate_path, {
                ...this.mutate_args,
                val: false
            });
        },
        ConfirmSignature()
        {
            this.$store.dispatch("validator/validateAll");
            // const canvas = this.$refs.canvas;
            // const ctx = canvas.getContext("2d");
            // const signed = ctx.getImageData(0, 0, canvas.width, canvas.height).data.some(channel => channel !== 0);
            // this.$store.commit(this.mutate_path, {
            //     ...this.mutate_args,
            //     val: signed
            // });

            // this.$store.dispatch("validator/validate", {
            //     key: this.id,
            //     val: signed
            // });
        },
        inputValidate(event)
        {
            // TODO
        },
        isValid()
        {
            return this.getIsValid(this.id) && this.required;
        }
    }
});
</script>