<template>
  <div class="camper-info">
    <p>
      <b>Read over the following information.</b>
    </p>
    <p>
      <i>- Please Turn in ALL medications upon arrival at camp.</i>
    </p>
    <p>
      <i>- Medications need to be picked up before the camper leaves the campgrounds.</i>
    </p>
    <p>
      <i>- The cost of prescription drugs needed during camp will be paid by the parent or guardian.</i>
    </p>
    <p>
      <i>- There is a first aid person at camper 24 hours a day.</i>
    </p>
    <p>
      <i>- Parents will be notified by the camp of any illness or injury that may occur during the camp.</i>
    </p>
    <p>
      <i>- Emergency intervention will be administered to a child considered in distress, pending safe transport to the nearest medical facility.</i>
    </p>
    <p>
      <i>- Parents will be notified to pick up uncooperative campers.</i>
    </p>
  </div>
    <sign-up-checkbox
      id="signup_important_info_accepted"
      :state_var="signup.info.important_info_accepted"
      mutate_path="signup/MUTATE_INFO"
      :mutate_args="{ key: 'important_info_accepted' }"
      validator="isTrue"
      validator_text="This field is required."
    >
      <b>I have read and understand the above information.</b>
    </sign-up-checkbox>
</template>

<script>
import { defineComponent } from "vue";

import { mapState } from "vuex";

import SignUpCheckbox from "./input/SignUpCheckbox.vue";
// import ReleaseModal from "../ReleaseModal.vue";

export default defineComponent ({
  name: "CamperSignUpFormRelease",
  components: {
    // ReleaseModal,
    SignUpCheckbox
  },
  computed: {
    ...mapState(["signup"]),
  },
  methods: {
    openRelease() {
      this.$store
        .dispatch("events/raiseEvent", {
          key: "open_modals",
          obj: {
            id: "release_modal",
          },
        })
        .then(() => {
          // console.log(this.$store.state.events);
        });
    },
  },
});
</script>