export default {
    methods: {
        transitionLabel(event, show) {
            let label = event.target.previousSibling;

            if (show === 'hide') {
                if (event.target.value == "") {
                    label.style.top = event.target.offsetTop + "px";
                    event.target.placeholder = label.innerText;
                    label.classList.add("camper-info__label--hidden");
                }
            } else {
                label.style.top = "-1px";
                event.target.placeholder = "";
                label.classList.remove("camper-info__label--hidden");
            }

            if (event.target.id == "dateOfBirth") {
                if (event.target.type == "text") {
                    event.target.type = "date";
                } else {
                    event.target.type = "text";
                }
            }
        },
    }
}