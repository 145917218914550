<template>
  <layout>
    <template v-slot:content>
      Dashboard
    </template>
  </layout>
</template>

<script>
import Layout from '../../templates/Layout.vue';

export default {
  name: "Dashboard",
  components: {
    Layout
  },
  methods:
  {
    logout() {
      this.$store
        .dispatch("serverPost", {
          route: "logout",
        })
        .then((response) => {
          if (response.data.logged_out) {
            this.$router.push({name: "login"});
          }
        });
    },
  },
  mounted() {},
};
</script>