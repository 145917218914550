import { createRouter, createWebHistory } from 'vue-router'
// both
import Login from "../views/Login.vue";

// campers
import SignUp from "../views/camper/SignUp.vue";
import ThankYou from "../views/camper/ThankYou.vue"

// admins
import Dashboard from "../views/admin/Dashboard.vue";

// store variables
import store from "../store/index.js";

const routes = [
  {
    path: "/",
    name: "sign-up",
    component: SignUp,
  },
  {
    path: "/thankyou",
    name: "thankyou",
    component: ThankYou,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    // TODO nav guards
    path: "/admin/dashboard",
    name: "admin.dashboard",
    component: Dashboard,
  }







  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }

  // TODO
  // {
  //   path: "/:catchall(.*)",
  //   component: NotFound,
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // TODO
  // store.dispatch("serverGet", {
  //   route: "logged_in"
  // }).then((res) => {
  //   console.log("in router beforeach", res);
  //     store.commit("admin/MUTATE", {
  //       key: "admin",
  //       val: res.data.admin
  //     });
  //   });
  next();
});

export default router;
