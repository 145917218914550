<template>
<div class="pagination__wrapper">
    <div class="page__input">
        <input type="text" ref="page_input" :placeholder="'Enter a page'"/>
        <button @click="searchPageButton">Select Page</button>
    </div>
    <div class="pagination">
        <div @click="toFirstPage"
            class="pagination__button pagination__button--first"
            :class="{'pagination__button--selectable': paginate_page !== FIRST_PAGE}"
        >
            <i class="fas fa-angle-double-left"></i>
        </div>

        <div @click="toPreviousPage"
            class="pagination__button pagination__button--previous"
            :class="{'pagination__button--selectable': paginate_page !== FIRST_PAGE}"
        >
            <i class="fas fa-angle-left"></i>
        </div>

        <div v-if="paginate_page >= Math.floor(pages_to_show / 2) + 1 && pages > pages_to_show"
            class="pagination__button pagination__button--page"
            :class="{'pagination__button--selectable': paginate_page !== FIRST_PAGE}"
            @click="selectPage(FIRST_PAGE)"
        >
            <span>{{FIRST_PAGE}}</span>
        </div>

        <div v-if="paginate_page >= Math.floor(pages_to_show / 2) + 1 && pages > pages_to_show" class="pagination__button pagination__button--dots">
            <i class="fas fa-ellipsis-h"></i>
        </div>

        <div v-for="idx in page_range"
            @click="selectPage(idx)"
            :key="'page'+idx"
            class="pagination__button pagination__button--page"
            :class="{
                'pagination__button--selectable': paginate_page !== idx,
                'pagination__button--selected': paginate_page === idx,
            }"
        >
            <span>{{idx}}</span>
        </div>

        <div v-if="paginate_page < pages - Math.floor(pages_to_show / 2) && pages > pages_to_show" class="pagination__button pagination__button--dots">
            <i class="fas fa-ellipsis-h"></i>
        </div>

        <div v-if="paginate_page < pages - Math.floor(pages_to_show / 2) && pages > pages_to_show"
            class="pagination__button pagination__button--page"
            :class="{'pagination__button--selectable': paginate_page !== pages}"
            @click="selectPage(pages)"
        >
            <span>{{pages}}</span>
        </div>

        <div @click="toNextPage"
            class="pagination__button pagination__button--next"
            :class="{'pagination__button--selectable': paginate_page !== pages}"
        >
            <i class="fas fa-angle-right"></i>
        </div>

        <div @click="toLastPage"
            class="pagination__button pagination__button--last"
            :class="{'pagination__button--selectable': paginate_page !== pages}"
        >
            <i class="fas fa-angle-double-right"></i>
        </div>
    </div>
</div>
</template>

<script>

export default {
    name: "Pagination",
    props: {
        vuex_dispatch_path: {
            type: String,
            default: null,
            // required: true,
        },
        pages: {
            type: Number,
            default() {
                return 20;
            },
            // required: true,
        },
        pages_to_show: {
            type: Number,
            default: 10,
        },
        page: {
            type: Number,
            default: 1,
        }
    },
    data() {
        return {
            paginate_page: 1,
            FIRST_PAGE: 1,
        }
    },
    computed: {
        page_range() {
            let page_start = 1;
            let to_show = this.pages_to_show;
            if (this.pages_to_show < 5) {
                to_show = 5;
            }

            if (this.pages > this.pages_to_show) {
                to_show -= 1;
                let shown = Math.floor(this.pages_to_show / 2) + 1;
                if (this.paginate_page < shown) {
                    page_start = 1;
                } else if (this.paginate_page < this.pages - Math.floor(this.pages_to_show / 2)) {
                    page_start = this.paginate_page - Math.floor(this.pages_to_show/2) + 2;
                    to_show -= 2;
                } else {
                    page_start = this.pages - Math.floor(this.pages_to_show/2) - 3;
                }
            } else {
                to_show = this.pages;
            }

            return [...Array(to_show).keys()].map(x => x+page_start);
        }
    },
    methods: {
        toNextPage() {
            if (this.paginate_page < this.pages) {
                this.selectPage(this.paginate_page+1);
            }
        },
        toPreviousPage() {
            if (this.paginate_page > 1) {
                this.selectPage(this.paginate_page-1);
            }
        },
        toFirstPage() {
            if (this.paginate_page !== this.FIRST_PAGE) {
                this.selectPage(this.FIRST_PAGE)
            }
        },
        toLastPage() {
            if (this.paginate_page !== this.pages) {
                this.selectPage(this.pages);
            }
        },
        searchPageButton() {
            // TODO if a NaN is enter then give an error.
            if (this.$refs.page_input.value !== "") {
                let input = Number(this.$refs.page_input.value)
                if (input || input === 0) {
                    if (input < this.FIRST_PAGE) {
                        this.selectPage(this.FIRST_PAGE);
                        this.$refs.page_input.value = this.FIRST_PAGE;
                    } else if (input > this.pages) {
                        this.selectPage(this.pages);
                        this.$refs.page_input.value = this.pages;
                    } else {
                        this.selectPage(input);
                    }
                }
            }
        },
        selectPage(idx) {
            this.paginate_page = idx;
            console.log(`Pagination.vue -> selectPage(${this.paginate_page})`)
            this.$emit("SELECT-PAGE", this.paginate_page);
        },
    },
    created() {
        this.paginate_page = this.page;
    }
}
</script>

<style lang="scss">
.pagination__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    padding: 5px;

    .pagination {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, 32px);

        .pagination__button {  -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
            width: 100%;
            height: 32px;
            border: 1px solid black;
            display: table;

            i, span {
                display: table-cell;
            }
            span {
                text-align: center;
                vertical-align: middle;
            }
        }

        .pagination__button--end {
            opacity: 1;
        }

        .pagination__button--selectable {
            opacity: .70;
            cursor: pointer;
        }

        .pagination__button:hover {
            opacity: 1;
        }

        .pagination__button--selected {
            opacity: 1;
            background-color: rgb(79, 170, 79);
            color: white;
        }

        .pagination__button--first {
            border-radius: 10px 0px 0px 10px;
        }

        .pagination__button--last {
            border-radius: 0px 10px 10px 0px;
        }
    }
}
</style>