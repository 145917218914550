<template>
    <input
        :value="this.state_var"
        :id="this.id"
        type="checkbox"
        @input="
            this.$store.commit(this.mutate_path, {
                ...mutate_args,
                val: $event.target.checked
            });
            inputValidate($event);
        "
        class="camper-info__input camper-info__input--checkbox"
        :class="(isValid() ? '' : 'camper-info__checkbox--invalid')"
    />
    <label :for="this.id" class="camper-info__label--checkbox">
        <slot></slot>
    </label>
    <div class="camper-info__content__validator"
        :class="!isValid() ? 'show--opacity' : 'hide--opacity'"
    >
        {{ getFieldMessage(this.id) }}
    </div>

</template>

<script>

import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
    name: "SignUpCheckbox",
    props: {
        "id": { },
        "required": { default: true },
        "state_var": { },
        "mutate_path": { },
        "mutate_args": { },
        "validator": { },
        "validator_text": { default: "" },
    },
    computed: {
        ...mapGetters("validator", ["getIsValid", "getFieldMessage"])
    },
    beforeMount() {
        if (this.validator)
        {
            this.$store.dispatch("validator/register", {
                key: this.id,
                validator: this.validator,
                message: this.validator_text,
                required: this.required
            });
        }
    },
    methods:
    {
        inputValidate(event) {
            if (!this.validator) return;

            this.$store.dispatch("validator/validate", {
                key: this.id,
                val: event.target.checked
            });
        },
        isValid() {
            if (this.validator == null)
                return true;

            return this.getIsValid(this.id) && this.required;
        }
    }
});

</script>