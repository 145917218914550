<template>
  <div class="container">
    <!-- <header-sign-up v-if="!admin" />
    <header-admin v-else /> -->
    <div class="content print-view" id="content">
      <div class="content-wrapper">
        <slot name="content"></slot>
      </div>
      <Footer />
      <loading-overlay></loading-overlay>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import HeaderSignUp from "../components/HeaderSignUp.vue";
import HeaderAdmin from "../components/HeaderAdmin.vue";
import Footer from "../components/Footer.vue";
import LoadingOverlay from "../components/LoadingOverlay.vue";

export default {
  name: "Layout",
  components: {
    HeaderSignUp,
    HeaderAdmin,
    Footer,
    LoadingOverlay
  },
  computed: {
    ...mapState("admin", ["admin"]),
  },
  mounted() {

  }
};
</script>