
<template>
<div class="camper-info">
    <!-- start address, city, Postal Code, and province -->
    <div class="camper-info__group camper-info__group--2">
        <sign-up-input
            :id="`signup_mailing_address`"
            type="text"
            label="Mailing Address"
            :state_var="signup.info.mailing_address"
            mutate_path="signup/MUTATE_INFO"
            :mutate_args="{ key: 'mailing_address' }"
            validator="isNotEmpty"
            validator_text="This field is required"
        />
        <sign-up-input
            :id="`signup_city`"
            type="text"
            label="Town/City"
            :state_var="signup.info.city"
            mutate_path="signup/MUTATE_INFO"
            :mutate_args="{ key: 'city'}"
            validator="isNotEmpty"
            validator_text="This field is required"
        />
        <sign-up-dropdown
            :id="`signup_province`"
            label="Province"
            :state_var="signup.info.province"
            mutate_path="signup/MUTATE_INFO"
            :mutate_args="{ key: 'province' }"
            validator="isNotEmpty"
            validator_text="This field is required"
        >
            <option value="" selected="true" disabled hidden>Province</option>
            <option value="AB">AB</option>
            <option value="BC">BC</option>
            <option value="MB">MB</option>
            <option value="NB">NB</option>
            <option value="NL">NL</option>
            <option value="NS">NS</option>
            <option value="ON">ON</option>
            <option value="PE">PE</option>
            <option value="QC">QC</option>
            <option value="SK">SK</option>
            <option value="YT">YT</option>
            <option value="NT">NT</option>
            <option value="NU">NU</option>
        </sign-up-dropdown>
        <sign-up-input
            :id="`signup_postal_code`"
            type="text"
            label="Postal code"
            max_length="7"
            :state_var="signup.info.postal_code"
            mutate_path="signup/MUTATE_INFO"
            :mutate_args="{ key: 'postal_code' }"
            validator="isPostalCode"
            validator_text="Invalid postal code ex. P2A 0H2"
            validator_timeout="1000"
        />
    </div>
    <!-- end address and city -->
</div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import SignUpInput from "./input/SignUpInput";
import SignUpDropdown from "./input/SignUpDropdown"

export default defineComponent ({
    name: "CamperSignUpFormMailing",
    components: {
        SignUpInput,
        SignUpDropdown
    },
    computed: {
        ...mapState(["signup"]),
        ...mapState("admin", ["campers"]),
        ...mapState(["validator"]),
        ...mapGetters("validator", ["getIsValid"])
    },
});
</script>

<style>

</style>