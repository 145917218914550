<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import url("../node_modules/@fortawesome/fontawesome-free/css/all.min.css");
@import url("../node_modules/@fortawesome/fontawesome-free/css/solid.min.css");
@import url("../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css");
</style>