function defaultState() {
    return {
        loading: {},
        open_modals: [],
    }
}


export default {
    namespaced: true,
    name: "Events",
    state: defaultState(),
    actions: {
        raiseEvent({commit}, {key, obj}) {
            return new Promise((resolve) => {
                commit("ADD_EVENT", {key, obj});
                resolve();
            });
        },
        setEvent({commit}, {key, value}) {
            return new Promise((resolve) => {
                commit("SET_EVENT", {key, value});
                resolve();
            });
        },
        dropEvent({commit}, key) {
            return new Promise((resolve) => {
                commit("NULL_EVENT", key)
            });
        },
        dropEventById({commit}, {key, id}) {
            return new Promise((resolve) => {
                commit("REMOVE_EVENT_BY_ID", {key, id});
                resolve();
            });
        }
    },
    mutations: {
        ADD_EVENT(state, {key, obj}) {
            console.log(state);
            console.log(key);
            // Check if the state key res is an array or an object
            if (state[key].length > 0) {
                let obj_not_found = false;
                for (let i = 0; i < state[key].length; i++) {
                    if (state[key][i] == obj) {
                        obj_not_found = true;
                    }
                }
                if (obj_not_found) {
                    state[key].push(obj);
                }
            } else {
                state[key] = obj;
            }
        },
        SET_EVENT(state, {key, value}) {
            state[key] = value
        },
        NULL_EVENT(state, key)
        {
            if (state[key] instanceof Object)
            {
                state[key] = {};
            }
            else if (state[key] instanceof Array)
            {
                state[key] = [];
            }
            else
            {
                state[key] = null;
            }
        },
        REMOVE_EVENT_BY_ID(state, {key, id}) {
            let idx = null;
            for (let i = 0; i < state[key].length; i++) {
                if (state[key][i].id = id) {
                    idx = i;
                    break;
                }
            }
            if (idx != null) {
                state[key].splice(idx, 1);
            }
        },
        REMOVE_EVENT_BY_IDX(state, {key, idx}) {
            state[key].splice(idx, 1);
        }
    }
}