<template>
<div class="camper-info__content camper-info__content--text">
    <label :for="this.id" class="camper-info__label camper-info__label--hidden camper-info__label--text">
        {{ this.label }}
    </label>
    <textarea
        :value="this.state_var"
        @input="
            this.$store.commit(this.mutate_path, {
                ...mutate_args,
                val: $event.target.value
            });
            inputValidate($event, true);"
        @focus="
            transitionLabel($event, 'show')
            updateColor($event, 'focus');"
        @blur="
            transitionLabel($event, 'hide');
            updateColor($event, 'blur');
            inputValidate($event, false);"
        :placeholder="this.placeholder != null ? this.placeholder : this.label"
        :id="this.id"
        :rows="this.rows"
        :class="
            `camper-info__input camper-info__input--textarea light ` +
            (isValid() ? '' : 'camper-info__input--invalid')"
    ></textarea>

    <div class="camper-info__content__validator"
        :class="!isValid() ? 'show--opacity' : 'hide--opacity'"
    >
        {{ getFieldMessage(this.id) }}
    </div>
</div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import transitionLabel from "../../../mixins/transitionLabel.js";

export default defineComponent ({
    name: "SignUpInput",
    data() {
        return {
            input_timeout: null
        };
    },
    props: {
        "id": { },
        "type": { },
        "label": { },
        "placeholder": { },
        "rows": { },
        "required": { default: true },
        "state_var": { },
        "mutate_path": { },
        "mutate_args": { },
        "validator": { },
        "validator_text": { default: "" },
        "validator_timeout": { },
    },
    computed: {
        ...mapGetters("validator", ["getIsValid", "getFieldMessage"])
    },
    beforeMount() {
        if (this.validator)
        {
            this.$store.dispatch("validator/register", {
                key: this.id,
                validator: this.validator,
                message: this.validator_text,
                required: this.required
            });
        }
    },
    methods: {
        updateColor(event, event_type)
        {
            // TODO move to a mixin
            if (event_type == "focus" || this.isValid())
            {
                event.target.classList.remove("light");
                event.target.classList.add("primary");
            }
            else if (event_type == "blur")
            {
                event.target.classList.remove("primary");
                event.target.classList.add("light");
            }
        },
        inputValidate(event, use_timeout) {
            if (!this.validator) return;

            if (!use_timeout || !this.validator_timeout) {
                this.$store.dispatch("validator/validate", {
                    key: this.id,
                    val: event.target.value
                });
                return;
            }

            if (this.input_timeout) clearTimeout(this.input_timeout);

            const id = this.id;
            const dispatch = this.$store.dispatch;
            this.input_timeout = setTimeout(() => {
                dispatch("validator/validate", {
                    key: id,
                    val: event.target.value
                });
            }, this.validator_timeout);
        },
        isValid() {
            if (this.validator == null)
                return true;

            return this.getIsValid(this.id) && this.required;
        }
    },
    mixins: [transitionLabel]
});
</script>