<template>
<!--
-- This is a generic DataTable that uses scoped slots to fill 
    in the columns and rows.
-->
<div class="table_container">
    <div class="table__search">
        <div class="table__search__container column--2 column--left">
            <i class="fas fa-search"></i> <!-- TODO move into input, with placeholder text that disappears when the serach gets focussed" -->
            <input type="search" />
        </div>
        <div class="table__pagination__container column--2 column--right">
            <pagination @SELECT-PAGE="selectPage" 
                :pages="pages"
                :page="page" 
            />
        </div>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th v-for="column in columns" :key="column['key']" @click="sortBy(column)">
                    <slot name="column" :column="column"></slot>
                </th>
            </tr>
        </thead>

        <tbody>
            <!-- https://v3.vuejs.org/guide/component-slots.html#destructuring-slot-props -->
            <tr v-for="row in rows" :key="key_prefix + row.id">
                <slot name="row" :row="row"></slot>
            </tr>
        </tbody>
    </table>
</div>
</template>

<script>
// TODO add row limit selection
// To handle the scoped data follow this video
// https://youtube.com/watch?v=GWdOucfAzTo 
import Pagination from "./Pagination.vue";

export default {
    name: "DataTable",
    components: {
        Pagination,
    },
    props: {
        columns: {
            type: Array,
            default: [],
            // required: true,
        },
        rows: {
            Type: Array,
            default: [],
            // required: true,
        },
        key_prefix: {
            Type: String,
            default: "row_"
        },
        pages: {
            Type: Number,
            default: 1
        },
        page: {
            Type: Number,
            default: 1
        },
        // row_request_function: {
        //     Type: Function,
        //     default: null,   
        // }
    },
    methods: {
        sortBy(column) {
            if (column.sortable) {
                console.log(column.key);
                // @TODO this.
                this.$emit("SORT-BY", column.key);
            }
        },
        selectPage(page) {
            this.$emit("SELECT-PAGE", page);
        }
    },
}
</script>

<style>
table {
    border-collapse: collapse;
}
th { 
    border: 1px solid black;
}
tr { 
    border: 1px solid black;
}
/* 
.row {
    
} */
.row--text-center {
    text-align: center;
}
td {
    border-left: 1px solid black;
    border-right: 1px solid black;
}
</style>