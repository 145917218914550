<template>

<div class="camper-info">
    <h3 class="camper-info__header camper-info__header-info">Medical Information</h3>
    <div class="camper-info__container">
        <div class="camper-info__group camper-info__group--2">
            <sign-up-input
                :id="`camper_${camper_idx}_health_care_card`"
                type="text"
                label="Alberta Healthcare Card #"
                max_length="10"
                :input_function="formatAbNumberEvent"
                placeholder="Alberta Healthcare Card #"
                :state_var="signup.info.campers[camper_idx].health_care_card"
                mutate_path="signup/MUTATE_CAMPER"
                :mutate_args="{ key: 'health_care_card', idx: this.camper_idx }"
                :required="false"
                validator="isAlbertaHealthCareNumber"
                validator_text="Invalid Alberta Healthcare Card #. Expected format: 12345-6789"
            />
        </div>
    </div>

    <br />
    <div class="camper-info__container">
        <span class="camper-info__explanation">Please list any allergies {{ getCamperHeader(camper_idx) }}</span>
        <span class="camper-info__explanation"><i>Please bring your own EPIPEN, allergy medications, and other medications.*</i></span>
        <div class="camper-info__group camper-info__group--2">
            <sign-up-text-area
                :id="`camper_${camper_idx}_allergies`"
                type="textarea"
                label="Allergies (optional)"
                placeholder="Ex. Bee sting, peanuts, etc."
                :required="false"
                rows="5"
                :state_var="signup.info.campers[camper_idx].allergies"
                mutate_path="signup/MUTATE_CAMPER"
                :mutate_args="{ key: 'allergies', idx: this.camper_idx }"
            />
        </div>
        <br/>
    </div>

    <div class="camper-info__container">
        <span class="camper-info__explanation">Please select any known medical conditions {{ getCamperHeader(camper_idx) }}</span>
        <div class="camper-info__group camper-info__group--2">
            <div class="camper-info__content camper-info__content--checkbox">
                <input
                    v-model="signup.info.campers[camper_idx].medical_conditions"
                    value="adhd"
                    type="checkbox"
                    :id="'adhd_'+camper_idx"
                    class="camper-info__input camper-info__input--checkbox"
                />
                <label :for="'adhd_'+camper_idx" class="camper-info__label camper-info__label--hidden--checkbox">ADHD</label>
            </div>
            <div class="camper-info__content camper-info__content--checkbox">
                <input
                    v-model="signup.info.campers[camper_idx].medical_conditions"
                    value="autism"
                    type="checkbox"
                    :id="'autism_'+camper_idx"
                    class="camper-info__input camper-info__input--checkbox"
                />
                <label :for="'autism_'+camper_idx" class="camper-info__label camper-info__label--hidden--checkbox">Autism</label>
            </div>
            <div class="camper-info__content camper-info__content--checkbox">
                <input
                    v-model="signup.info.campers[camper_idx].medical_conditions"
                    value="asthma"
                    type="checkbox"
                    :id="'asthma_'+camper_idx"
                    class="camper-info__input camper-info__input--checkbox"
                />
                <label :for="'asthma_'+camper_idx" class="camper-info__label camper-info__label--hidden--checkbox">Asthma</label>
            </div>
            <div class="camper-info__content camper-info__content--checkbox">
                <input
                    v-model="signup.info.campers[camper_idx].medical_conditions"
                    value="diabetes"
                    type="checkbox"
                    :id="'diabetes_'+camper_idx"
                    class="camper-info__input camper-info__input--checkbox"
                />
                <label :for="'diabetes_'+camper_idx" class="camper-info__label camper-info__label--hidden--checkbox">Diabetes</label>
            </div>
            <div class="camper-info__content camper-info__content--checkbox">
                <input
                    v-model="signup.info.campers[camper_idx].medical_conditions"
                    value="bed_wetting"
                    type="checkbox"
                    :id="'bed_wetting_'+camper_idx"
                    class="camper-info__input camper-info__input--checkbox"
                />
                <label :for="'bed_wetting_'+camper_idx" class="camper-info__label camper-info__label--hidden--checkbox">Bed Wetting</label>
            </div>
            <div class="camper-info__content camper-info__content--checkbox">
                <input
                    v-model="signup.info.campers[camper_idx].medical_conditions"
                    value="fasd"
                    type="checkbox"
                    :id="'fasd_'+camper_idx"
                    class="camper-info__input camper-info__input--checkbox"
                />
                <label :for="'fasd_'+camper_idx" class="camper-info__label camper-info__label--hidden--checkbox">FASD</label>
            </div>
        </div>
        <div class="camper-info__group camper-info__group--1">
            <sign-up-text-area
                :id="`camper_${camper_idx}_other_health_conditions`"
                type="textarea"
                label="Other health conditions (optional)"
                rows="5"
                :required="false"
                :state_var="signup.info.campers[camper_idx].other_health_conditions"
                mutate_path="signup/MUTATE_CAMPER"
                :mutate_args="{ key: 'other_health_conditions', idx: this.camper_idx }"
            />
        </div>
        <div class="camper-info__group camper-info__group--1">
            <sign-up-text-area
                :id="`camper_${camper_idx}_physical_restrictions`"
                type="textarea"
                label="Physical restrictions (optional)"
                rows="5"
                :required="false"
                :state_var="signup.info.campers[camper_idx].physical_restrictions"
                mutate_path="signup/MUTATE_CAMPER"
                :mutate_args="{ key: 'physical_restrictions', idx: this.camper_idx }"
            />
        </div>
        <!-- THIS IS A HACK -->
        <div class="pdf-break"></div>
        <div class=":camper-info__group camper-info__group--1">
            <sign-up-text-area
                :id="`camper_${camper_idx}_medical_bans`"
                type="textarea"
                label="Medications that CANNOT be administered"
                :required="false"
                rows="5"
                :state_var="signup.info.campers[camper_idx].medical_bans"
                mutate_path="signup/MUTATE_CAMPER"
                :mutate_args="{ key: 'medical_bans', idx: this.camper_idx }"
            />
        </div>
        <div class=":camper-info__group camper-info__group--1">
            <sign-up-text-area
                :id="`camper_${camper_idx}_care_requirements`"
                type="textarea"
                label="Care requirements"
                rows="5"
                :required="false"
                :state_var="signup.info.campers[camper_idx].care_requirements"
                mutate_path="signup/MUTATE_CAMPER"
                :mutate_args="{ key: 'care_requirements', idx: this.camper_idx }"
            />
        </div>
    </div>
</div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapState } from 'vuex';
import SignUpInput from "./input/SignUpInput.vue";
import SignUpTextArea from "./input/SignUpTextArea.vue";

export default defineComponent({
    name: "CamperSignUpFormMedical",
    props: ['camper_idx'],
    computed: {
        ...mapState(["signup"]),
        ...mapGetters("signup", ["getCamperFullName"]),
    },
    components: {
        SignUpInput,
        SignUpTextArea
    },
    mixins: [
    ],
    methods: {
        getCamperHeader(idx) {
            let fullname = this.getCamperFullName(idx);
            if (fullname != "") {
                fullname = "for " + fullname;
            }
            return fullname;
        },
        formatAbNumberEvent(event)
        {
            let value = event.target.value;
            value = value.replace(/^([0-9]{5})([0-9]{4})$/, "$1-$2");
            event.target.value = value;
        }
    }
});
</script>