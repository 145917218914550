<template>
<div class="camper-info">
    <sign-up-signature :id="'signup_signature'"
    :required="true"
    :state_var="signup.info.signed"
    mutate_path="signup/MUTATE_INFO"
    :mutate_args="{key: 'signed'}"
    validator_text="Signature is required"
    />
</div>
</template>

<script>
import { defineComponent } from 'vue';

import { mapState } from "vuex";

import SignUpSignature from './input/SignUpSignature.vue';

export default defineComponent({
    name: "CamperSignUpSignature",
    components: {
        SignUpSignature
    },
    computed: {
        ...mapState(["signup"])
    },
    mounted()
    {
        console.log("here");
    }
});
</script>