<template>
<div id="signup" class="sign-up">

    <!-- <span class="btn btn--global" @click="PopulateForm">
        <span>Fill test</span>
    </span> -->

    <div class="sign-up__container page-break" v-for="i in campers.length" :key="'camper-' + i">
        <h2 class="sign-up__container__title sign-up__container__title--camper">Camper {{ i }} {{ getCamperHeader(Number(i)-1) }}</h2>
        <camper-sign-up-form-basic-info :camper_idx="Number(i)-1" />
        <camper-sign-up-form-medical :camper_idx="Number(i)-1" />
        <camper-sign-up-form-rockwall-release :camper_idx="Number(i)-1" />
    </div>
    <div class="sign-up__container sign-up__container--add-camper">
        <h2 class="sign-up__container__title sign-up__container__title--add-camper">Add Another Camper?</h2>
        <div class="camper-info">
            <div class="btn-group">
                <span class="btn btn--global" @click="addAnotherCamper">
                    <span>Add</span>
                </span>
                <span class="btn btn--global" :class="{'btn--disabled': campers.length <= 1}" @click="removeLastCamper">
                    <span>Remove</span>
                </span>
            </div>
            <p>Total Campers: {{ campers.length }}</p>
        </div>
    </div>

    <div class="sign-up__container">
        <h2 class="sign-up__container__title">Guardian Contacts</h2>
        <camper-sign-up-form-guardian-info />
    </div>

    <div class="sign-up__container">
        <h2 class="sign-up__container__title">Mailing Information</h2>
        <camper-sign-up-form-mailing />
    </div>

    <div class="sign-up__container">
        <h2 class="sign-up__container__title">Contacts</h2>
        <camper-sign-up-form-emergency-contact-info />
        <camper-sign-up-form-pickup-person-info />
    </div>

    <div class="sign-up__container">
        <h2 class="sign-up__container__title">Important Information</h2>
        <camper-sign-up-form-important-information />
    </div>

    <div class="sign-up__container">
        <h2 class="sign-up__container__title">Release/Consent Form</h2>
        <camper-sign-up-form-release />
    </div>

    <div class="sign-up__container">
        <h2 class="sign-up__container__title">Signature</h2>
        <camper-sign-up-form-signature />
    </div>
    <br />

    <div class="sign-up__container sign-up__container--submit">
        <div class="camper-info__group camper-info__group--1">
            <div class="camper-info__button">
                <div class="btn" @click="sendSignupInfo">
                    SIGN UP
                </div>
                <div class="camper-info__content__validator"
                    :class="this.has_errors ? 'show--opacity' : 'hide--opacity'"
                >
                    <div v-if="this.server_error == ''">
                        There are errors in some fields:
                        <ul>
                            <li v-for="(field, idx) in this.invalid_fields"
                            :key="`invalid_field_${idx}`"
                            >
                                {{ field }}
                            </li>
                        </ul>
                    </div>
                    <div v-else>
                        {{ server_error }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
</div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapState } from 'vuex';
import CamperSignUpFormBasicInfo from "./CamperSignUpFormBasicInfo.vue";
// import CamperSignUpFormSkills from "./CamperSignUpFormSkills.vue";
import CamperSignUpFormMedical from "./CamperSignUpFormMedical.vue";
import CamperSignUpFormRockwallRelease from "./CamperSignUpFormRockwallRelease.vue"
// import CamperSignUpFormEmergency from './CamperSignUpFormEmergency.vue';
import CamperSignUpFormMailing from "./CamperSignUpFormMailing.vue";
import CamperSignUpFormGuardianInfo from './CamperSignUpFormGuardianInfo.vue';
import CamperSignUpFormEmergencyContactInfo from './CamperSignUpFormEmergencyContactInfo.vue';
import CamperSignUpFormPickupPersonInfo from './CamperSignUpFormPickupPersonInfo.vue';
import CamperSignUpFormRelease from "./CamperSignUpFormRelease.vue";
import CamperSignUpFormImportantInformation from "./CamperSignUpFormImportantInformation.vue";
import CamperSignUpFormSignature from "./CamperSignUpFormSignature.vue";

import { Buffer } from "buffer";
import CalculateAge from "../../../../shared_lib/age";
import convertPageToPdf from "../../mixins/convertPageToPdf";

export default defineComponent({
    name: "CamperSignUpForm",
    components: {
        CamperSignUpFormBasicInfo,
        // CamperSignUpFormSkills,
        CamperSignUpFormMedical,
        CamperSignUpFormRockwallRelease,
        // CamperSignUpFormEmergency,
        CamperSignUpFormMailing,
        CamperSignUpFormGuardianInfo,
        CamperSignUpFormEmergencyContactInfo,
        CamperSignUpFormPickupPersonInfo,
        CamperSignUpFormRelease,
        CamperSignUpFormImportantInformation,
        CamperSignUpFormSignature
    },
    computed: {
        ...mapState(["signup"]),
        ...mapState("signup", [
            "info"
        ]),
        campers: (state) => state.signup.info.campers,
        ...mapGetters("signup", ["getCamperFullName"]),
        ...mapGetters("validator", ["getIsAllValid", "getInvalidFields"])
    },
    data() {
        return {
            has_errors: false,
            invalid_fields: [],
            server_error: ""
        };
    },
    methods: {
        getCamperHeader(idx) {
            let fullname = this.getCamperFullName(idx);
            if (fullname != "") {
                fullname = "- " + fullname;
            }
            return fullname;
        },
        addAnotherCamper() {
            this.$store.commit("signup/APPEND_NEW_CAMPER");
        },
        removeLastCamper() {
            this.$store.commit("signup/REMOVE_LAST_CAMPER");
        },
        PopulateForm()
        {

        },
        RemovePdfAddedElements()
        {
            for (let i = 0; i < this.campers.length; i++)
            {
                // TODO get the parent node from here
                let camp_week_dropdown = document.getElementById(`camper_${i}_camp_week`);
                let camp_week_textbox = document.getElementById(`camper_${i}_camp_week_text`);
                camp_week_dropdown.classList.toggle("hide");
                camp_week_textbox.remove();

                let gender_dropdown = document.getElementById(`camper_${i}_gender`);
                let gender_textbox = document.getElementById(`camper_${i}_gender_text`);
                gender_dropdown.classList.toggle("hide");
                gender_textbox.remove();
            }

            let province_dropdown = document.getElementById(`signup_province`);
            let province_textbox = document.getElementById(`signup_province_text`);
            province_dropdown.classList.toggle("hide");
            province_textbox.remove();
        },
        async ErrorCheck()
        {
            // Reset the errors
            this.server_error = "";
            this.has_errors = false;

            await this.$store.dispatch("validator/validateAll");

            this.has_errors = !this.getIsAllValid();

            if (this.has_errors)
            {
                let invalid_fields = this.invalid_fields
                invalid_fields = this.getInvalidFields();

                invalid_fields.forEach((field, i) => {
                    field = field.replace(/camper_[0-9]/, "");
                    field = field.replace(/guardian/, "_guardian"); // HACK
                    field = field.replace(/pickup/, "_pick-up"); // HACK
                    field = field.replace("_", "");
                    field = field.replaceAll("_", " ");
                    field = field.replaceAll("signup", "");
                    invalid_fields[i] = field;
                });

                this.invalid_fields = invalid_fields;
            }

            return this.has_errors;
        },
        async sendSignupInfo()
        {
            // Reset the errors
            this.server_error = "";

            if (await this.ErrorCheck())
            {
                return;
            }

            this.$store.dispatch("events/raiseEvent", {key: "loading", obj: {text: "Loading", enabled: true}});

            const signup = this.signup;
            const store = this.$store;
            const campers = signup.info.campers;

            const send_campers = [];

            campers.forEach((camper) => {
                camper.guardians = signup.info.guardians;
                camper.emergency_contacts = signup.info.emergency_contacts;
                camper.pickup_person = signup.info.pickup_person;
                camper.mailing_address = signup.info.mailing_address;
                camper.city = signup.info.city;
                camper.province = signup.info.province;
                camper.postal_code = signup.info.postal_code;
                camper.important_info_accepted = signup.info.important_info_accepted;
                camper.photo_terms_accepted = signup.info.photo_terms_accepted;
                camper.liability_released = signup.info.liability_released;
                camper.physician_released = signup.info.physician_released;
                camper.terms_accepted = signup.info.terms_accepted;

                console.log(camper);

                // If the validator made it this far then it must be true
                camper.signed = true;


                // Calculate their age
                const week_idx = camper.meta.selected_camp_week_idx;

                // Set the camp week
                camper.camp_week = this.signup.camp_weeks[week_idx].value;

                camper.age = CalculateAge(camper.date_of_birth, this.signup.camp_weeks[week_idx].date);

                // Copy the campers, and delete meta
                const send_camper = { ...camper };
                delete send_camper.meta;
                send_campers.push(send_camper);
            });

            const pdf = await convertPageToPdf();
            const router = this.$router;
            const this_ = this;

            const pdf_data = Buffer.from(pdf.output("arraybuffer"));
            store.dispatch("serverPost", {
                route: "signup",
                data: { raw:send_campers, pdf:pdf_data }
            }).then((response) => {
                // Go to then next page
                store.dispatch("events/dropEvent", "loading");

                router.push({name: "thankyou"})
            }, (err) => {
                store.dispatch("events/dropEvent", "loading");
                this_.server_error = "Error. Server is unable to process your registration, please go to our contact page and let us know. https://www.riversidebiblecamp.com/contact-us. Thank you."
                this_.has_errors = true;
            });

            window.scrollTo(0, document.body.scrollHeight);
        }
    },
});
</script>