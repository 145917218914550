function CalculateAge(date_of_birth, camp_week)
{
    // Parse the birth date string into a Date object
    const birth_date = new Date(date_of_birth);

    // Get the current date
    const camp_date = new Date(camp_week);

    // Calculate the difference in years
    let age = camp_date.getFullYear() - birth_date.getFullYear();

    // Check if the birthday has occurred this year
    if (
        camp_date.getMonth() < birth_date.getMonth() ||
        (camp_date.getMonth() === birth_date.getMonth() &&
            camp_date.getDate() < birth_date.getDate())
    )
    {
        age--;
    }

    return age;
}

module.exports = CalculateAge;
