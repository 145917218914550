<template>
    <nav class="nav">
        <ul class="nav__list nav__list--left">
            <li class="nav__link nav__link--left"><router-link :to="{name: 'admin.dashboard'}">Dashboard</router-link></li>
            <li class="nav__link nav__link--right"><router-link :to="{name: 'sign-up'}">Sign Up</router-link></li>
            <!-- <li class="nav__link nav__link--right"><router-link :to="{name: 'sign-up'}">right2</router-link></li>
            <li class="nav__link"><router-link :to="{name: 'sign-up'}">normal</router-link></li>
            <li class="nav__link nav__link--left"><router-link :to="{name: 'sign-up'}">left1</router-link></li> -->
        </ul>
        <ul class="nav__list nav__list--right">
            <!-- @TODO make into dropdown and move logout into it--> 
            <li class="nav__link nav__link--left"><a class="cursor--pointer" :to="{name: 'sign-up'}"><i class="fas fa-user-circle"></i>{{ admin.username }}<i class="fas fa-angle-down"></i></a></li>
            <li class="nav__link nav__link--right"><a class="cursor--pointer" @click="logoutAdmin">Logout</a></li>
            <!-- <li class="nav__link nav__link--right"><router-link :to="{name: 'sign-up'}">right2</router-link></li>
            <li class="nav__link"><router-link :to="{name: 'sign-up'}">normal</router-link></li>
            <li class="nav__link nav__link--left"><router-link :to="{name: 'sign-up'}">left1</router-link></li> -->
        </ul>
    </nav>
</template>

<script> 
import { mapState } from 'vuex';

export default {
    name: "HeaderAdmin",
    components: {

    },
    computed: {
        ...mapState("admin", ["admin"]),
    },
    methods: {
        logoutAdmin() {
            this.$store.dispatch("admin/logout").then(() => {
                this.$router.push({name: "login"});
            });
        }
    }
}
</script>

<style lang="scss">
    .fas {
        padding: 0 6px 0 6px;
        text-align: center;
        vertical-align: middle;
    }
</style>