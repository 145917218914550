<template>
  <default-layout>
    <template v-slot:content>
      <div class="container">
        <div class="login__container">
          <div class="login__header">
            <h2>Administration Login</h2>
          </div>
          <div class="login__content">
            <div class="login__content__username">
              <label for="username" class="label label--username">Username: </label>
              <input type="text" name="username" v-model="username" class="textbox textbox--username" @input="usernameValidation" />
            </div>

            <div class="login__content__password">
              <label for="password" class="label label--password">Password: </label>
              <input type="password" name="pa ssword" v-model="password" class="textbox textbox--password" @input="passwordValidation" @keyup="enterPressed"/>
            </div>

            <div class="login__content__button">
              <button class="button button--login" @click="login">Login</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </default-layout>
</template>

<script>
import DefaultLayout from "../templates/Layout.vue";

export default {
  name: "Login",
  components: { DefaultLayout },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    usernameValidation() {
      // TODO remove injection of sorts
      return true;
    },
    passwordValidation() {
      // TODO remove injection of sorts
      return true;
    },
    enterPressed(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.login();
      };
    },
    login() {
      if (this.usernameValidation() && this.passwordValidation()) {
        this.$store.dispatch("serverPost", {
          route: "login",
          data: {
            username: this.username,
            password: this.password,
          },
        }).then(response => {
          if (response.data.logged_in) {
            this.$router.push({name: "admin.dashboard"});
          } else {
            // @TODO show "error username or password is incorrect"
            console.log("failed to log in");
          }
        });
      }
    }
  },
  mounted() {},
};
</script>