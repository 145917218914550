<template>
<div class="camper-info__content camper-info__content--text">
    <label :for="this.id" class="camper-info__label camper-info__label--hidden camper-info__label--text">
        {{ this.label }}
    </label>
    <select
        :value="this.state_var"
        @input="
            this.$store.commit(this.mutate_path, { ...this.mutate_args, val: $event.target.value});
            updateDropdownColor($event);
            transitionLabel($event, 'hide');
            inputValidate($event)"
        @focus="transitionLabel($event, 'show')"
        @blur="
            transitionLabel($event, 'hide');
            inputValidate($event)"
        :id="this.id"
        class="camper-info__input camper-info__input--dropdown light"
        :class="(getIsValid(this.id, true) && this.required ? '' : 'camper-info__input--invalid')"
    >
        <slot>
            <option value="" selected="true" disabled hidden>{{ this.default_item }}</option>
            <option :selected="this.state_var == idx" v-for="(week, idx) in options" :key="this.id + '_' + idx" :value="idx">{{ week.text }}</option>
        </slot>
    </select>
    <div class="camper-info__content__validator"
        :class="!getIsValid(this.id, true) && this.required ? 'show--opacity' : 'hide--opacity'"
    >
        {{ getFieldMessage(this.id) }}
    </div>
</div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import transitionLabel from "../../../mixins/transitionLabel.js";

export default defineComponent ({
    name: "SignUpDropDown",
    props: {
        "id": { },
        "label": { },
        "required": { default: true },
        "state_var": { },
        "mutate_path": { },
        "mutate_args": { },
        "validator": { },
        "validator_text": { },
        "default_item": { default: "Choose an item" },
        "options": { default: null }
    },
    mixins: [
        transitionLabel
    ],
    computed: {
        ...mapGetters("validator", ["getIsValid", "getFieldMessage"])
    },
    mounted() {
        this.$store.dispatch("validator/register", {
            key: this.id,
            validator: this.validator,
            message: this.validator_text,
            required: this.required
        });
    },
    methods: {
        updateDropdownColor(event, event_type)
        {
            // TODO move to a mixin
            if (event_type == "focus" || this.getIsValid(this.id, true))
            {
                event.target.classList.remove("light");
                event.target.classList.add("primary");
            }
            else if (event_type == "blur")
            {
                event.target.classList.remove("primary");
                event.target.classList.add("light");
            }
        },
        inputValidate(event, use_timeout) {
            if (!this.validator) return;

            this.$store.dispatch("validator/validate", {
                key: this.id,
                val: event.target.value,
                required: this.required,
                message: "Required. Choose an option."
            });
        }
    },
});
</script>
