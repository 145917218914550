<template>
<div class="camper-info__content camper-info__content--text">
    <label :for="this.id" :id="`${this.id}_label`"
        :class="`camper-info__label camper-info__label--hidden camper-info__label--${this.type}`">
        {{ this.label }}
    </label>
    <input
        :value="this.state_var"
        @click="click_function($event)"
        @beforeinput="before_input_function($event)"
        @input="
            this.input_function($event);
            this.$store.commit(this.mutate_path, {
                ...mutate_args,
                val: $event.target.value
            });
            inputValidate($event, true);"
        @focus="
            this.focus_function($event);
            transitionLabel($event, 'show')
            updateColor($event, 'focus');"
        @blur="
            this.blur_function($event);
            transitionLabel($event, 'hide');
            updateColor($event, 'blur');
            inputValidate($event, false);"
        :type="this.type"
        :placeholder="this.placeholder ? this.placeholder : this.label"
        :id="this.id"
        :maxlength="this.max_length"
        :rows="this.rows"
        :min="type == 'date' ? min_date : ''"
        :max="type == 'date' ? max_date : ''"
        :class="
            `camper-info__input camper-info__input--${type} light ` +
            (isValid() ? '' : 'camper-info__input--invalid')"
    />
    <div class="camper-info__content__validator"
        :class="!isValid() ? 'show--opacity' : 'hide--opacity'"
    >
        {{ getFieldMessage(this.id) }}
    </div>
</div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import transitionLabel from "../../../mixins/transitionLabel.js";
import sleep from "../../../../../shared_lib/sleep"

export default defineComponent ({
    name: "SignUpInput",
    data() {
        return {
            input_timeout: null
        };
    },
    props: {
        "id": { type: String },
        "type": { type: String },
        "label": { type: String },
        "click_function": { type: Function, default: () => {} },
        "before_input_function": { type: Function, default: () => {} },
        "input_function": { type: Function, default: () => {} },
        "focus_function": { type: Function, default: () => {} },
        "blur_function": { type: Function, default: () => {} },
        "max_length": { type: String },
        "placeholder": { type: String },
        "rows": { type: String },
        "state_var": { type: undefined },
        "min_date": { type: String },
        "max_date": { type: String },
        "mutate_path": { type: String },
        "mutate_args": { type: Object },
        "required": { type: Boolean, default: true },
        "validator": { type: [String, Function]  },
        "validator_text": { type: String },
        "validator_timeout": { type: String },
    },
    beforeMount() {
        // Register validator
        if (this.validator)
        {
            this.$store.dispatch("validator/register", {
                key: this.id,
                validator: this.validator,
                message: this.validator_text,
                required: this.required
            });
        }
    },
    computed: {
        ...mapGetters("validator", ["getIsValid", "getFieldMessage"])
    },
    methods: {
        // TODO I could combine the inputs into a massive parent one
        async updateColor(event, event_type)
        {

            // Delay for a moment for any async functions to catch up
            await sleep(10);

            // TODO move to a mixin
            if (event_type == "focus" || this.isValid(this.id, true))
            {
                event.target.classList.remove("light");
                event.target.classList.add("primary");
            }
            else if (event_type == "blur")
            {
                event.target.classList.remove("primary");
                event.target.classList.add("light");
            }
        },
        inputValidate(event, use_timeout) {
            if (!this.validator) return;

            if (!use_timeout || !this.validator_timeout) {
                this.$store.dispatch("validator/validate", {
                    key: this.id,
                    val: event.target.value
                });
                return;
            }

            if (this.input_timeout) clearTimeout(this.input_timeout);

            const id = this.id;
            const dispatch = this.$store.dispatch;
            this.input_timeout = setTimeout(() => {
                dispatch("validator/validate", {
                    key: id,
                    val: event.target.value
                });
            }, this.validator_timeout);
        },
        isValid() {
            if (this.validator == null)
                return true;

            return this.getIsValid(this.id);
        }
    },
    mixins: [transitionLabel]
});
</script>