import router from "../../router/router.js";

function defaultState() {
    return {
        admin: null,
        campers: {
            data: [],
            pages: 0,
            page: 1,
            total_count: 0,
            row_limit: 0,
        },
    }
}

function handleAdminRequestErrors({ state, disptach, commit }, error) {
    if (error.response.data.error === "unauthorized") {
        commit("admin/RESET");
        router.push({ name: "login" });
    }
}

export default {
    namespaced: true,
    name: "admin",
    state: defaultState(),
    actions: {
        updateField({state, commit}, kwargs) {
            commit('MUTATE', kwargs);
        },
        logout({dispatch, commit}, kwargs) {
            return new Promise((resolve, reject) => {
                dispatch("serverGet", {
                    route: "logout"
                }, { root: true }).then(() => {
                    commit("RESET");
                    resolve();
                });
            }, error => {
                console.log("ERROR -> modules/Admin/Logout", error.response);
                handleAdminRequestErrors(this, error);
            });
        },
        getAllCampers({ state, dispatch, commit }) {
            // TODO get pagination_limit from params
            return new Promise((resolve, reject) => {
                console.log("IN -> modules/Admin/GetAllCampers -> page:", state.campers.page);
                dispatch("serverGet", {
                    route: "admin/get-all-campers",
                    params: {
                        sort_by: "id",
                        limit: 10,
                        page: state.campers.page,
                    }
                }, { root: true }).then((res) => {
                    commit("UPDATE_CAMPERS", {
                        campers: res.data.campers,
                        pages: res.data.pages,
                        total_count: res.data.total_count
                    });
                    resolve();
                }, error => {
                    console.log("ERROR -> modules/Admin/GetAllCampers", error.response);
                    handleAdminRequestErrors(this, error);
                });
            });
        },
    },
    mutations: {
        MUTATE(state, kwargs) {
            state[kwargs.key] = kwargs.val;
        },
        MUTATE_CAMPERS(state, kwargs) {
            state.campers[kwargs.key] = kwargs.val;
        },
        UPDATE_CAMPERS(state, kwargs) {
            state.campers.data = kwargs.campers;
            state.campers.pages = kwargs.pages;
            state.campers.total_count = kwargs.total_count;
        },
        RESET(state) {
            Object.assign(state, defaultState());
        }
    },
    modules: {
    }
}