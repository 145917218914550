<template>
    <div class="camper-info">
        <h3 class="camper-info__header">Riverside Bible Camp Rock Wall</h3>

        <b>
            WAIVER RELEASE AND INDEMNITY. THIS IS A LEGAL DOCUMENT PLEASE READ THOROUGHLY.
        </b>

        <p>
            Warning: By signing this form you relinquish your right to bring court action to be compensated for any injury
            or loss to yourself as well as the right of your personal representative to compensation for your death.
        </p>

        <p>
            All checkboxes must be checked and signed in order to climb.
        </p>

        <p>
            Initials of parent if under 18 OR initials of participant if 18 years or older.
        </p>

        <p>
            <b>1.</b> I, the Undersigned understand and acknowledge that I am aware of the fact that despite
            the due diligence of Riverside Bible camp staff, there are risks associated with or related to the use
            of the climbing wall, (including the risk of severe or fatal injury), to myself particulars of which
            include but and not limited to the following:
            (a) injuries resulting for falling and impacting climbing wall faces or the ground, including an object or
            objects resting on the floor;
            (b) injuries resulting for activities such as climbing, belaying, repelling, rescue systems and other rope
            techniques;
            (c) injuries resulting from falling climbers or objects such as rope or climbing hardware;
            (d) injuries resulting from physical activity of the sport itself including bit not limited to neck and back
            strains, muscle strains,
            muscle pulls, tendon and ligament damage as well as other typical athletic injuries or more serious injuries. I
            voluntarily accept these
            physical risks.
        </p>

        <p>
            <b>2.</b> I understand that by signing the document, I, my successors, heirs, assigns or personal
            representative waive the right to sue or otherwise claim against Riverside Bible Camp or its
            employees, volunteers, officials, sponsors, directors, agents, coaches, instructors or
            independent contractors for an loss or damage connected with any property loss or personal injury that I sustain
            while participating in
            or preparing for any program or activity of Riverside Bible Camp. I further understand clearly that I, my
            successors, heirs, assigns and
            personal representatives waive the right to sure or other wise claim against Riverside Bible Camp or its
            employees, volunteers, officials,
            sponsors, coaches, agents, directors, instructors or independent contractors in the loss or injury suffered
            results wholly or in part from
            the negligence of Riverside Bible Camp, its employees, volunteers, officials, sponsors, coaches, agents,
            directors, instructors or
            independent contractors or from the negligence of any third party, including other participants in the programs.
        </p>

        <p>
            <b>3.</b> I further agree to indemnify and save harmless Riverside Bible Camp employees, volunteers,
            officials, sponsors, coaches, agents, directors, instructors or independent contractors, from any and all
            actions, claims, demands, losses or suits of any nature resulting from the arising from our of my
            participation in any program in Riverside Bible Camp or my use of its facilities or from the
            participation of my infant child in any program in Riverside Bible Camp or from that child's use of its facilities.
        </p>

        <p>
            <b>4.</b> I acknowledge that I am of the full age 18 years and that I have read and fully understood this agreement prior to signature.
IN WITNESS WHEREOF I have executed this document at the city of Dixonville in Province of Alberta this
        </p>

        <sign-up-checkbox :id="`signup_rockwall_release_${camper_idx}`"
            :state_var="signup.info.campers[this.camper_idx].rockwall_release"
            mutate_path="signup/MUTATE_CAMPER"
            :mutate_args="{ idx: camper_idx, key: 'rockwall_release' }">
            <b>
                I release Riverside Bible Camp of liability for the rock climbing wall for the above camper.
            </b>
        </sign-up-checkbox>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

import { mapState } from "vuex";

import SignUpCheckbox from './input/SignUpCheckbox.vue';

export default defineComponent({
    name: "Rockwall Release",
    props: [
        'camper_idx'
    ],
    components: {
        SignUpCheckbox
    },
    computed: {
        ...mapState(["signup"])
    }
});
</script>