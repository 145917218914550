<template>
    <nav class="nav">
        <ul class="nav__list nav__list--left">
            <!-- <li class="nav__link nav__link--left"><router-link :to="{name: 'sign-up'}">About</router-link></li> -->
            <li class="nav__link nav__link--right"><router-link :to="{name: 'sign-up'}">Sign Up</router-link></li>
            <!-- <li class="nav__link nav__link--right"><router-link :to="{name: 'sign-up'}">right2</router-link></li>
            <li class="nav__link"><router-link :to="{name: 'sign-up'}">normal</router-link></li>
            <li class="nav__link nav__link--left"><router-link :to="{name: 'sign-up'}">left1</router-link></li> -->
        </ul>
        <ul class="nav__list nav__list--right">
            <!-- <li class="nav__link nav__link--left"><router-link :to="{name: 'sign-up'}">About</router-link></li> -->
            <li class="nav__link nav__link--right"><router-link :to="{name: 'login'}">Login</router-link></li>
            <!-- <li class="nav__link nav__link--right"><router-link :to="{name: 'sign-up'}">right2</router-link></li>
            <li class="nav__link"><router-link :to="{name: 'sign-up'}">normal</router-link></li>
            <li class="nav__link nav__link--left"><router-link :to="{name: 'sign-up'}">left1</router-link></li> -->
        </ul>
    </nav>
</template>

<script> 

export default {
    name: "HeaderSignUp",
}
</script>

<style lang="scss">
</style>