<template>
    <div class="input__container" :class="container_class" :title="input_title">
        <label 
            v-if="label_text.length > 0"
            :for="input_id" 
            class="input__label" 
            :class="label_class"
        >
            {{ label_text }}
        </label>
        <input
            v-if="input_type != 'checkbox' && input_type != 'radio'"
            :value="vuex_var"
            @input="updateVuexStore($event)"
            :type="input_type"
            :id="input_id"
            class="input__field"
            :class="input_class"
            :placeholder="input_placeholder"
            :required="input_required"
        />
        <input
            v-else
            :checked="vuex_var === input_placeholder"
            :value="input_placeholder"
            @input="updateVuexStore($event)"
            :type="input_type"
            :id="input_id"
            class="input__field"
            :class="input_class"
            :required="input_required"
        />
    </div>
</template>

<script> 

export default {
    name: "CustomInput",
    props: {
        container_class: {
            default: "",
            type: String,
        },
        input_class: {
            default: "",
            type: String,
        },
        input_id: {
            require: true,
            default: "",
            type: String,
        },
        input_title: {
            require: true,
            default: "",
            type: String,
        },
        input_placeholder: {
            require: true,
            default: "",
            type: String,
        },
        input_required: {
            require: true,
            default: false,
            type: Boolean,
        },
        input_type: {
            require: true,
            default: "text",
            type: String,
        },
        label_class: {
            default: "",
            type: String,
        },
        label_text: {
            require: true,
            default: "",
            type: String,
        },
        mutate_path: {
            default: "",
            type: String,
        },
        val: {
            require: false,
            default: null,
        },
        vuex_key: {
            require: true,
            default: "",
            Type: String,
        },
        vuex_var: {
            default: null,
        },
    },
    methods: {
        updateVuexStore(e) {
            this.$store.commit(this.mutate_path, {
                key: this.vuex_key,
                val: e.target.value
            });
        },
    },
    mounted() {
    }
}
</script>