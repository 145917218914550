<template>
  <layout>
    <template v-slot:content>
      <div class="riverside__title">
        <h2>
          <span>Sign up for Riverside Bible Camp</span>
          <span @click="savePdf" class="btn btn--save pdf-hide">Save</span>
          <span @click="saveBlankPdf" class="btn btn--print pdf-hide">Print blank</span>
        </h2>
      </div>
      <div class="disclaimer">
        <span>
          *Information provided below is confidential and will be used for administration purposes only.*
        </span>
      </div>
      <camper-sign-up-form />


    </template>
  </layout>
</template>

<script>
import { mapState } from "vuex";

import Layout from "../../templates/Layout.vue";
import VuexInput from "../../components/VuexInput.vue";

import DataTable from "../../components/DataTable.vue";
import CamperSignUpForm from '../../components/camper-signup-form/CamperSignUpForm.vue';
import convertPageToPdf from "../../mixins/convertPageToPdf";

import sleep from "../../../../shared_lib/sleep"

export default {
  name: "SignUp",
  components: {
    Layout,
    VuexInput,
    DataTable,
    CamperSignUpForm,
  },
  data() {
    return {
      columns: [
        { text: "id", key: "id", sortable: true },
        { text: "First Name", key: "firstname", sortable: true },
        { text: "Last Name", key: "lastname", sortable: true },
        { text: "Date of Birth", key: "date_of_birth", sortable: true },
        { text: "Cabin Partner", key: "camp_partner", sortable: true },
        { text: "Camp Register", key: "camp_register", sortable: true },
        { text: "Date Signed Up", key: "date_signed_up", sortable: true },
        { text: "Paid", key: "paid", sortable: true },
        { text: "Actions", key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState(["signup"]),
    ...mapState("admin", ["campers"]),
  },
  methods: {
    selectPage(page) {
      this.$store.commit("admin/MUTATE_CAMPERS", {
        key: "page",
        val: page,
      });
      this.getAllCampers();
    },
    updateSimpleInput(e, key) {
      this.$store.commit("signup/MUTATE_CAMPER", {
        key: key,
        val: e.target.value,
      });
    },
    updateGuardian(e, idx, key) {
      this.$store.commit("signup/MUTATE_GUARDIAN", {
        idx: idx,
        key: key,
        val: e.target.value,
      });
    },
    transitionLabel(event, show) {
      let label = event.target.previousSibling;
      if (show === 'hide') {
        label.style.top = event.target.offsetTop + "px";
        event.target.placeholder = label.innerText;
      } else {
        label.style.top = "-1px";
        event.target.placeholder = "";
      }

      if (event.target.id == "dateOfBirth") {
        if (event.target.type == "text") {
          event.target.type = "date";
        } else {
          event.target.type = "text";
        }
      }
    },
    async savePdf()
    {
      this.$store.dispatch("events/raiseEvent", {key: "loading", obj: {text: "Loading", enabled: true}});
      const pdf = await convertPageToPdf("pdf-view");
      pdf.save("Riverside Bible camp signup form");
      await sleep(100);
      this.$store.dispatch("events/dropEvent", "loading");
    },
    async saveBlankPdf() {
      this.$store.dispatch("events/raiseEvent", {key: "loading", obj: {text: "Loading", enabled: true}});
      const signature_canvases = document.querySelectorAll(".signature_canvas");
      // Parallel array
      const canvas_mem = [];

      for (let i = 0; i < signature_canvases.length; ++i)
      {
        const canvas = signature_canvases[i];
        const ctx = canvas.getContext("2d");
        canvas_mem.push(ctx.getImageData(0, 0, canvas.width, canvas.height));

        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }

      const pdf = await convertPageToPdf("save-view");
      pdf.save("Riverside Bible camp signup blank form");

      for (let i = 0; i < signature_canvases.length; ++i)
      {
        const canvas = signature_canvases[i];
        const ctx = canvas.getContext("2d");

        ctx.putImageData(canvas_mem[i], 0, 0);
      }

      await sleep(100);
      this.$store.dispatch("events/dropEvent", "loading");
    },
    navToRelease() {
      this.$router.push({ name: "release" });
    },
    createDummyCamper() {
      // console.log("saving camper");
      this.$store.dispatch("serverPost", {
        route: "create-dummy-camper",
        data: this.signup,
      });
    },
    deleteAllCampers() {
      // console.log("delete all campers");
      this.$store.dispatch("serverGet", {
        route: "clear-all",
      });
    },
    getAllCampers() {
      this.$store.dispatch("admin/getAllCampers");
    },
    viewCamper(camper_id) {

    }

  },
};
</script>

<style>
.fa--camper-table {
  width: 32px;
}
.fa--good {
  color: green;
}
.fa--bad {
  color: red;
}
</style>
