import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import Requests from "./actions/Requests.js";
import signup from "./modules/SignUp.js";
import admin from "./modules/Admin.js";
import events from "./modules/Events.js";

import validator from "./modules/Validator.js";

export default createStore({
  state: {
    base_url: ""
  },
  mutations: {
    MUTATE(state, kwargs) {
      state[kwargs.key] = kwargs.val;
    },
    MUTATE_ANY(_, kwargs) {
        kwargs.state[kwargs.key] = kwargs.val;
    },
  },
  actions: {
    ...Requests
  },
  modules: {
    signup,
    admin,
    events,
    validator
  },
  mixins: [
  ],
  plugins: [
    // createPersistedState(),
  ]
});
